// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const API_URLS: { [key: string]: string } = {
    DEVELOPMENT: 'https://merchant.development.neonomics.io/api/v1',
    PRODUCTION: 'https://merchant.neonomics.io/api/v1',
    SANDBOX: 'https://merchant.sandbox.neonomics.io/api/v1',
};

const CHECKOUT_REQ_URLS: { [key: string]: string } = {
    DEVELOPMENT: 'https://checkout.development.io/api/v1/checkout-requests',
    PRODUCTION: 'https://checkout.neonomics.io/api/v1/checkout-requests',
    SANDBOX: 'https://checkout.sandbox.neonomics.io/api/v1/checkout-requests',
};

const ENABLED_FEATURE_FLAGS: { [key: string]: string } = {
    DEVELOPMENT: 'EMBEDDED_LOGIN_ALL_ORGS,MERCHANT_KYC',
    PRODUCTION: 'MERCHANT_KYC',
    SANDBOX: 'EMBEDDED_LOGIN_ALL_ORGS',
};

const EMBEDDED_LOGIN_ORG_IDS: { [key: string]: string } = {
    DEVELOPMENT: '',
    PRODUCTION: `
    f33610a0-ac2f-4d0b-bc74-d5d2f0f3f5e9,
    ae581a21-82a5-4200-afcd-c3c404aec4be,
    a6582b9f-74bd-4c11-9153-03c45c2926b5,
    c98270ee-66bc-43d5-9c27-1a43d7b934ee,
    1249aa6f-6f8e-46cd-b6ce-370a13569959,
    4bc81f71-ea72-4220-9a8c-1c4d009c5c9b,
    `,
    SANDBOX: '',
};

const getApiUrl = (env: string) => {
    const apiUrl: string = API_URLS[env];
    return apiUrl ?? 'http://localhost:8089/api/v1';
};

const getCheckoutReqUrl = (env: string) => {
    const checkoutReqUrl: string = CHECKOUT_REQ_URLS[env];
    return checkoutReqUrl ?? 'https://checkout.development.io/api/v1/checkout-requests';
};

const getEnabledFeatureFlags = (env: string) => {
    const checkoutReqUrl: string = ENABLED_FEATURE_FLAGS[env];
    return checkoutReqUrl ?? 'EMBEDDED_LOGIN_ALL_ORGS,MERCHANT_KYC';
};

const getEmbeddedLoginOrgIds = (env: string) => {
    const embeddedLoginOrgIds: string = EMBEDDED_LOGIN_ORG_IDS[env];
    return embeddedLoginOrgIds ?? '';
};

export const environment = {
    production: false,
    apiUrl: getApiUrl('%ENVIRONMENT%'),
    checkoutReqUrl: getCheckoutReqUrl('%ENVIRONMENT%'),
    enabledFeatureFlags: getEnabledFeatureFlags('%ENVIRONMENT%'),
    embeddedLoginOrgIds: getEmbeddedLoginOrgIds('%ENVIRONMENT%'),
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
